$(document).ready(function () {

	var path = window.location.pathname;
	if( ( path.indexOf('/cineteca-nacional-de-chile/cineteca-online-cclm') != -1 || path.indexOf('/national-film-library/online-film-library') != -1 ) && window.location.pathname.indexOf('/page/') != -1) {
		$('html, body').animate({ scrollTop: $('#peliculasDestacadasCineteca').offset().top - 200 }, 'slow', function () {});
	}

	$('#deployExposicionesAnteriores').on('click', function(){
		if( $(this).hasClass('active') ) {
			$('#allExposicionesAnteriores').removeClass('hide');
		} else {
			$('#allExposicionesAnteriores').addClass('hide');
		}
	});

	$('#deployMenuMisTickets').on('click', function(event){
		event.preventDefault();

		$("#menuMisTicketsPorPagar").empty();
		$("#menuMisTicketsPagados").empty();

		var user_id = localStorage.getItem('ticketera_id');
		var order_id = localStorage.getItem('orden_id');
		if( user_id != '' && user_id != null ) {

			$.ajax({
				url: $('#cclm_site_url').val()+'/wp-json/tickets/obtain',
				method: "POST",
				data: { user_id: user_id, order_id: order_id },
				success: function (response) {
					var ticketsPorPagar = [];
					var ticketsPagados = [];
					var ticketsForQR = [];
					var totalTickets = 0;

					response.forEach(ticket => {
						if(ticket['orden_payment'] == '1' || ticket['orden_payment'] == 1) {
							var ticketID = String(ticket['ticket_id']);
							var ticketLength = ticketID.length;
							for(var i = 0; i < (10 - ticketLength); i++) {
								var ticketID = '0' + ticketID;
							}
							var ticketPagado =
								'<div class="ticket-paid-container" style="border-bottom: 1px solid #3a3a3a; display: flex; padding: 1rem;">' +
									'<div style="width: 65%; padding-right: .5rem;">' +
										'<h4 class="case-upper" style="margin: 0 0 .25rem;">' + ticket['titulo'] + '</h4>' +
										'<span class="show case-upper font-size-mini" style="font-weight: 300; letter-spacing: 1px; margin-bottom: 1rem;">' + ticket['subtitulo'] + '</span>' +
										'<span class="show font-size-tiny w-bold">' + ticket['ubicacion'] + '</span>' +
										'<span class="show font-size-tiny w-bold">' + ticket['ticket_fecha'] + ' ' + ticket['ticket_hora'] + '</span>' +
										'<span class="font-size-mini">1 ticket ' + ticket['ticket_tipo'] + '</span><span class="font-size-mini w-bold" style="padding-left: .5rem;">$' + ticket['ticket_precio'] + '</span>' +
										'<button class="case-upper show" data-download-ticket="'+ticket['titulo']+'-'+ticket['ticket_id']+'" style="border: 1px solid #3a3a3a; font-size: 12px; margin-top: .5rem; padding: .5rem 1rem;">Descargar ticket</button>' +
									'</div>' +
									'<div class="qr-holder" data-qr-ticket='+ticket['ticket_sha']+'></div>' +
									'<div style="position: relative; width: 5%;"><span style="position: absolute; left: 16px; top: 16px; transform: rotate(90deg); font-size: 12px; transform-origin: 0 0;">' + ticketID + '</span></div>' +
								'</div>' +
								'<div class="ticket-paid-download" style="border: 1px solid #3a3a3a; display: none; max-width: 620px">' +
									'<div style="background-image: url(' + ticket['imagen'] + '); background-size: cover; background-position: center; background-repeat: no-repeat; width: 35%;"></div>' +
									'<div style="width: 65%; padding: 1rem">' +
										'<h4 class="case-upper" style="margin: 0 0 .25rem;">' + ticket['titulo'] + '</h4>' +
										'<span class="show case-upper font-size-mini" style="font-weight: 300; letter-spacing: 1px; margin-bottom: 1rem;">' + ticket['subtitulo'] + '</span>' +
										'<span class="show font-size-tiny w-bold">' + ticket['ubicacion'] + '</span>' +
										'<span class="show font-size-tiny w-bold gutter-bottom-2">' + ticket['ticket_fecha'] + ' ' + ticket['ticket_hora'] + '</span>' +
										'<span style="font-size: 14px; font-weight: 700; margin-top: 2rem">1 ticket ' + ticket['ticket_tipo'] + '</span><span style="font-size: 14px; font-weight: 700; padding-left: .5rem;">$' + ticket['ticket_precio'] + '</span>' +
									'</div>' +
									'<div style="position: relative; top: 24px" class="qr-holder" data-qr-ticket='+ticket['ticket_sha']+'></div>' +
									'<div style="position: relative; width: 5%;"><span style="position: absolute; left: 16px; top: 38px; transform: rotate(90deg); font-size: 12px; transform-origin: 0 0;">' + ticketID + '</span></div>' +
								'</div>';
							ticketsPagados.push(ticketPagado);
							ticketsForQR.push(ticket);

						} else {

							totalTickets += parseInt(ticket['ticket_precio']);
							var ticketPorPagar =
								'<div class="ticket-por-pagar-container" style="border-top: 1px solid #3a3a3a; display: flex; padding: 1rem;">' +
									'<div style="width: 30%; background-image: url('+ticket['imagen']+'); background-size: cover; background-repeat: no-repeat; background-position: center;"></div>' +
									'<div style="width: 70%; padding-left: .5rem;">' +
										'<h4 class="case-upper" style="margin: 0 0 .25rem;">' + ticket['titulo'] + '</h4>' +
										'<span class="show case-upper" style="font-weight: 300; font-size: 12px; letter-spacing: 1px; margin-bottom: 1rem;">' + ticket['subtitulo'] + '</span>' +
										'<span style="font-size: 14px; font-weight: 700;">1 ' + ticket['ticket_tipo'] + '</span><span style="font-size: 14px; font-weight: 700; padding-left: .5rem;">$' + ticket['ticket_precio'] + '</span>' +
										'<button style="font-weight: bold; float: right; margin-top: 1rem; font-size: 12px; padding: 0;" data-delete-item="'+ticket['ticket_id']+'" data-order="'+order_id+'">Eliminar compra</button>' +
									'</div>' +
								'</div>';
							ticketsPorPagar.push(ticketPorPagar);

						}
					});

					if(ticketsPorPagar.length > 0) {
						var porPagarContainer = $(
							'<div style="padding: 1rem;">' +
								'<h3 style="margin: 0; font-size: 16px;">Tickets por comprar</h3>' +
								'<span style="font-size: 12px;">Si estás seguro de tu compra, confirma tus tickets y comienza el proceso de pago.</span>' +
							'</div>'
						).appendTo("#menuMisTicketsPorPagar");


						for(var i = 0; i < ticketsPorPagar.length; i++) {
							$(ticketsPorPagar[i]).appendTo("#menuMisTicketsPorPagar");
						}

						var porPagarContainer = $(
							'<div style="display: flex; padding: 1rem; margin-top: 1rem;">' +
								'<div style="width: 30%;"></div>' +
								'<div style="width: 70%;">' +
									'<strong style="float: left; letter-spacing: 1px;">TOTAL</strong>' +
									'<strong style="float: right; letter-spacing: 1px;">$' + totalTickets + ' CLP</strong>' +
								'</div>' +
							'</div>' +
							'<div style="display: flex; justify-content: flex-end; padding: 1rem;">' +
								'<a href="'+$('#cclm_site_url').val()+'/ticketera/?pid=1" class="button button--ticketera-next" style="float: none!important;">Pagar</a>' +
							'</div>'
						).appendTo("#menuMisTicketsPorPagar");

						setTimeout(function(){
							jQuery.each(jQuery('[data-delete-item]'), function (e) {
								var $this = $(this);
								$this.on('click', function(e){
									var id = $this.data('delete-item');
									var order = $this.data('order');
									$.ajax({
										url: $('#cclm_site_url').val()+'/wp-json/ticket/delete',
										method: 'POST',
										data: { id: id, order_id: order },
										success: function (response) {
											if(response) {
												$this.closest('div.ticket-por-pagar-container').remove();
												$('#deployMenuMisTickets').click();
											}
										},
										error: function (response) {
											console.log('error', response);
										}
									});
								});
							});
						}, 50);
					}

					if(ticketsPagados.length > 0) {
						for(var i = 0; i < ticketsPagados.length; i++) {
							$(ticketsPagados[i]).appendTo("#menuMisTicketsPagados");
						}

						setTimeout(function(){
							jQuery.each(jQuery('[data-qr-ticket]'), function (e) {
								var $this = $(this);
								new QRCode($this[0], {
									text: $('#cclm_site_url').val() + '/ticketera/verificacion/?tid=' + $this.data('qr-ticket'),
									width: 100,
									height: 100,
									colorDark : '#211d18',
									colorLight : '#ffffff',
									correctLevel : QRCode.CorrectLevel.L
								});
							});

							jQuery.each(jQuery('[data-download-ticket]'), function (e) {
								var $this = $(this);
								$this.on('click', function(e){
									$this.closest('div.ticket-paid-container').next('div.ticket-paid-download').css('display', 'flex');
									var ticket = '<div style="padding: 1rem;">'+ $this.closest('div.ticket-paid-container').next('div.ticket-paid-download').prop('outerHTML') + '</div>';
									$this.closest('div.ticket-paid-container').next('div.ticket-paid-download').css('display', 'none');

									var title = $this.data('download-ticket').toLowerCase();
									title = title.split(' ').join('-');
									title = title.split(',').join('-');
									title = title.split('.').join('-');
									title = title.normalize('NFD').replace(/[\u0300-\u036f]/g,'');
									var opt = { filename: 'ticket-'+title+'.pdf'};
									html2pdf(ticket, opt);
								});
							})
						}, 50);
					}
				},
				error: function (response) {
					console.log('error', response);
				}
			});

			$('body').addClass('no-scroll-red');
			$('#menuMisTicketsContainer').addClass('open');
			setTimeout(function(){
				$('#menuMisTicketsBody').addClass('open');
				$('#menuMisTicketsNoLogin').addClass('hide');
			}, 50);
		} else {
			$('body').addClass('no-scroll-red');
			$('#menuMisTicketsContainer').addClass('open');
			// $('#misTicketsContent').addClass('hide');
			setTimeout(function(){
				$('#menuMisTicketsBody').addClass('open');
				$('#menuMisTicketsNoLogin').removeClass('hide');
			}, 50);
		}
	});

	$('#collapseMenuMisTickets').on('click', function(event){
		event.preventDefault();
		$('body').removeClass('no-scroll-red');
		$('#menuMisTicketsContainer').removeClass('open');
		$('#menuMisTicketsBody').removeClass('open');

		$("#menuMisTicketsPorPagar").empty();
		$("#menuMisTicketsPagados").empty();
	});

	jQuery.each(jQuery('[data-close-mis-tickets]'), function (e) {
		var $this = $(this);
		$this.on('click', function() {
			event.preventDefault();
			$('body').removeClass('no-scroll-red');
			$('#menuMisTicketsContainer').removeClass('open');
			$('#menuMisTicketsBody').removeClass('open');

			$("#menuMisTicketsPorPagar").empty();
			$("#menuMisTicketsPagados").empty();
		});
	});

	jQuery.each(jQuery('[data-touch]'), function (e) {
		var $this = $(this);
		var status = $this.data('touch');

		var $window = $(this);

		if ($window.width() < 768) {
			$this.removeClass('no-touch').addClass('touch');
			$this.attr('data-touch', true);
		} else {
			$this.addClass('no-touch').removeClass('touch');
			$this.attr('data-touch', false);
		}

		$(window).on('resize', function () {
			var $window = $(this);
			if ($window.width() < 768) {
				$this.removeClass('no-touch').addClass('touch');
				$this.attr('data-touch', true);
			} else {
				$this.addClass('no-touch').removeClass('touch');
				$this.attr('data-touch', false);
			}
		});
	});

	jQuery.each(jQuery('[data-area-name="mobile-aux"] [data-child] a'), function (e) {
		var $this = $(this);

		$this.on('click', function () {
			$('body').removeClass('nav-open');
			$('[data-role="nav-body"]').removeClass('deployed');
			$('[data-role="nav-deployer"]').removeClass('deployed');
		});
	});

	jQuery.each(jQuery('[data-role="video-player"]'), function () {
		var $this_video = $(this);
		var video = this;
		var $deployer = $($this_video.siblings()).find('[data-role="video-player-deployer"]');

		$deployer.on("click", function () {
			var $video_prev = $this_video.siblings('[data-role="video-prev"]');

			if (video.paused == true) {
				video.play();
				$deployer.addClass('playing');
				$this_video.attr('controls', 'true');
				$video_prev.addClass('hide').removeClass('paused');
			} else {
				video.pause();
				$video_prev.addClass('paused').removeClass('hide').removeAttr('style');
				$deployer.removeClass('playing');
				$this_video.removeAttr('controls');
			}
		});

		if (video.paused == true) {
			$deployer.addClass('check-playing');
		} else {
			$deployer.removeClass('check-playing');
		}
	});

	jQuery.each(jQuery('[data-role="divider-row"]'), function () {
		var $this = $(this),
			$target = $this.children('[data-role="divider-target"]'),
			countext = $target.children().length,
			divider = $this.data('count-paragraph') || 2,
			split = divider / 2 || 2,
			leftcol_qant = Math.round(countext / split),
			contador = 0,
			left_col = '',
			col_html = '',
			right_col = '';

		if (countext >= divider) {
			jQuery.each(jQuery($target.children()), function () {
				var lilkid = $(this).get(0).outerHTML;
				contador++;
				if (contador <= leftcol_qant) {
					left_col += lilkid;
				} else {
					right_col += lilkid;
				}
			});

			col_html = '<td class="left">' + left_col + '</td>' + '<td class="right">' + right_col + '</td>';
			$target.replaceWith(col_html);
		} else {
			var new_html = $target.html();
			$target.replaceWith('<td>' + new_html + '</td><td></td>');
		}
	});

	jQuery.each(jQuery('[data-role="divider-grid"]'), function () {
		var $this = $(this),
			data_class = $this.data('class'),
			countext = $this.children().length,
			divider = $this.data('count-paragraph') || 2,
			split = divider / 2 || 2,
			leftcol_qant = Math.round(countext / split),
			contador = 0,
			left_col = '',
			col_html = '',
			right_col = '';

		if (countext >= divider) {
			jQuery.each(jQuery($this.children()), function () {
				var lilkid = $(this).get(0).outerHTML;
				contador++;
				if (contador <= leftcol_qant) {
					left_col += lilkid;
				} else {
					right_col += lilkid;
				}
			});

			col_html = '<div class="' + data_class + '">' + left_col + '</div>' + '<div class="' + data_class + '">' + right_col + '</div>';
			$this.html(col_html);
		} else {
			var new_html = $this.html();
			$target.html('<div class="' + data_class + '">' + new_html + '</td>');
		}
	});

	jQuery.each(jQuery('[data-role="floating-footer"]'), function () {
		var $hidden = $(this),
			$holder = $(this).parents('[data-role="floating-footer-holder"]'),
			$limit_top = $('#top').height();

		$holder.attr('style', 'height: ' + $hidden.height() + 'px');

		$(window).scroll( function () {
			var scroll = $(window).scrollTop();
         var stop = $holder.offset().top - ($limit_top * 1.5);

			if(scroll >= $limit_top && scroll <= stop){
				$hidden.addClass('active');
			}else{
				$hidden.removeClass('active');
			}
		});
	});

	jQuery.each(jQuery('[data-role="peekaboo"]'), function () {
		var $seeker = $(this);
		var $player = $('[data-name="peekaboo-player"]');

		$seeker.on("click", function () {
			var $message_active = $seeker.html();

			$player.toggleClass('wait');

			if ($seeker.html() === $seeker.data('less')) {
				$seeker.html($seeker.data('more'));
			} else {
				$seeker.html($seeker.data('less'));
			}
		});

	});

	jQuery.each(jQuery('img[data-srcset]'), function () {
		var $this_img = $(this);
		var src_set = $this_img.data('srcset') || '';

		if(src_set !== ''){
			var sizes = src_set.split(',');
			var resize = [];
			var default_src = $this_img.attr('src');

			if(sizes.length > 0){
				$this_img.attr('data-srcfull', default_src);
				sizes.forEach(function (value, key) {
					value = value.slice(0,-1);
					var size = value.split(' ');

					resize.push({ size: size[1], src: size[0] })
				});
				resize.reverse();
				resize.forEach(function (fuente, key) {
					var window_width = $(window).width();
					if(window_width <= fuente['size']){ $this_img.attr('src', fuente['src']) }
					if(window_width > resize[0]['size']){ $this_img.attr('src', default_src) }
				});
			}
		}
	});

	jQuery.each(jQuery('[data-srcset]:not(img)'), function () {
		var $this_figure = $(this);
		var src_set = $this_figure.data('srcset') || '';

		if(src_set !== ''){
			var sizes = src_set.split(',');
			var resize = [];
			var default_src = $this_figure.attr('style');

			if(sizes.length > 0){
				$this_figure.attr('data-srcfull', default_src);
				sizes.forEach(function (value, key) {
					value = value.slice(0,-1);
					var size = value.split(' ');

					resize.push({ size: size[1], bg: size[0] })
				});
				resize.reverse();
				resize.forEach(function (fuente, key) {
					var window_width = $(window).width();
					if(window_width <= fuente['size']){ $this_figure.attr('style', fuente['bg']) }
					if(window_width > resize[0]['size']){ $this_figure.attr('style', default_src) }
				});
			}
		}
	});

	jQuery.each(jQuery('[data-role="product"]'), function () {
		var $item = $(this);

		$item.on('click', function(event){
			var target = $item.data('target');
			var $superparent = $item.parents('section.horizon');

			$($superparent.find('[data-role="product"]')).removeClass('active');
			$($superparent.find('[data-role="product"][data-target="'+target+'"]')).addClass('active');

			$($superparent.find('[data-role="card"]')).removeClass('active');
			$($superparent.find('[data-product="'+target+'"]')).addClass('active');

			$item.addClass('active');

			event.preventDefault();
		});
	});

	jQuery.each(jQuery('[data-role="card"]'), function () {
		var $close_btn = $($(this).find('[data-role="close"]'))

		$close_btn.on('click', function(event){
			var $superparent = $($(this).parents('section.horizon'));

			$($superparent.find('[data-role="product"]')).removeClass('active');
			$($superparent.find('[data-role="card"]')).removeClass('active');
		});
	});
});

$(document).ready(function(){
	var everythingLoaded = setInterval(function () {
		if (/loaded|complete/.test(document.readyState)) {
			clearInterval(everythingLoaded);
			jQuery.each(jQuery('[data-role="tab-parent"]'), function () {
				var $this = $(this);
				var current_height = $this.height();

				$this.css('min-height', current_height);
			});
		}
	}, 2000);

	jQuery.each(jQuery('[data-behavior="scrollTop"]'), function (e) {
		var $this = $(this);
		$this.on('click', function () {
			$('html, body').animate({ scrollTop: 0 }, 'slow', function () {});
		});
	});

	jQuery.each(jQuery('[data-behavior="scrollTopParent"]'), function (e) {
		var $this = $(this);
		$this.on('click', function () {
			if(!$this.hasClass('active')) {
				$('html, body').animate({ scrollTop: $this.parent().offset().top - 200 }, 'slow', function () {});
			}
		});
	});

	jQuery.each(jQuery('[data-behavior="videoControl"]'), function (e) {
		var $this = $(this);
		var videoSource = $this.siblings('[data-role="videoSource"]')[0];
		$this.on('click', function () {
			if(videoSource.paused) {
				$this.hide();
				videoSource.controls = true;
				videoSource.play();
			}
		});
	});

	jQuery.each(jQuery('[data-role="videoSource"]'), function (e) {
		var $this = $(this);
		var videoControl = $this.siblings('[data-behavior="videoControl"]');
		$this.on('click', function () {
			if(!$this.paused) {
				videoControl.eq(0).show();
				$this[0].controls = false;
				$this[0].pause();
			}
		});
	});

	//Desplegar ficha tecnica al cargar
	jQuery.each(jQuery('[data-ficha-tecnica]'), function (e) {
		var $this = $(this);
		$this.click();
	});

	//Transparente al inicio
	jQuery.each(jQuery('.transparent'), function (e) {
		let stateCheck = setInterval(() => {
			if ($('[data-transparent]').height() > 0) {
				clearInterval(stateCheck);

				var $this = $(this);
				$this.removeClass('transparent');
			}
		}, 50);
	});

	//Controles de galeria modal
	jQuery.each(jQuery('[data-open-gallery]'), function (e) {
		var $this = $(this);
		$this.on('click', function(){
			var parent = $this.closest('div.slick-slide');
			var index = parent.data('slick-index');
			$('[data-fixed-gallery').slick("goTo", index);
			$('body').addClass('no-scroll');
			$('[data-fixed-gallery]').removeClass('hide');
		});

		$(document).on('tap', $this, function(){
			var parent = $this.closest('div.slick-slide');
			var index = parent.data('slick-index');
			$('[data-fixed-gallery').slick("goTo", index);
			$('body').addClass('no-scroll');
			$('[data-fixed-gallery]').removeClass('hide');
		});
	});
	jQuery.each(jQuery('[data-close-slider]'), function (e) {
		var $this = $(this);
		$this.on('click touchstart', function(){
			$('body').removeClass('no-scroll');
			$('[data-fixed-gallery]').addClass('hide');
			var index = $this.data('close-slider');
			$('[data-main-gallery').slick("goTo", index);
		});
	});
	//Controles de galeria modal

	jQuery.each(jQuery('.calendar-box'), function () {

		$('#datepicker').datepicker($.datepicker.regional['es'] = {
			closeText: 'Cerrar',
			prevText: '<Ant',
			nextText: 'Sig>',
			currentText: 'Hoy',
			monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
			monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
			dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
			dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
			dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
			weekHeader: 'Sm',
			dateFormat: 'dd/mm/yy',
			firstDay: 1,
			isRTL: false,
			showMonthAfterYear: false,
			yearSuffix: ''
			// beforeShowDay: function(date) {
			// 	var highlight = eventDates[date];
			// 	if( highlight ) {
			// 		return [true, 'ui-datepicker-event-day', 'Fecha con evento asociado'];
			// 	} else {
			// 		return [false, '', ''];
			// 	}
			// }
		});

		$("#datepicker").on("change",function(){
			var selected = $(this).val().split('/').reverse().join('');
			$.post($('#site-url').val()+"/wp-json/format/date", { date: selected }, function(data){
				if(data['status'] == -1){ window.location.href = '/bad-request/'; }
				$('#actual-date').html(data);
			});
			$.post($('#site-url').val()+"/wp-json/search/exposiciones", { date: selected }, function(data){
				if(data['status'] == -1){ window.location.href = '/bad-request/'; }
				data = data != '' ? data : '<div class="gr-12 gutter-double gutter@tablet font-centered"><span style="color: #606060;font-size: 24px; display: block; margin-bottom: 2rem;">No contamos con exposiciones para este día.</span><span style="color: #606060;font-size: 24px;">Vuelve al calendario para seleccionar otra fecha.</span></div>' ;
				var section = '';
				section += 	'<div class="row no-margin">';
				section += 		'<div class="cover__title__container">';
				section += 			'<hr class="cover__title__hr">';
				section += 			'<h3 class="cover__title__text font-size-30">Exposiciones</h3>';
				section += 		'</div>';
				section += 	'</div>';
				section += 	'<div class="container">';
				section += 		'<div class="row" data-equalize="target" data-mq="tablet-down" data-eq-target=".box">';
				section += 			data;
				section += 		'</div>';
				section += 	'</div>';
				$('#agenda-exposiciones-container').html(section);
			});
			$.post($('#site-url').val()+"/wp-json/search/cineteca", { date: selected }, function(data){
				if(data['status'] == -1){ window.location.href = '/bad-request/'; }
				data = data != '' ? data : '<div class="gr-12 gutter-double gutter@tablet font-centered"><span style="color: #606060;font-size: 24px; display: block; margin-bottom: 2rem;">No contamos con funciones para este día.</span><span style="color: #606060;font-size: 24px;">Vuelve al calendario para seleccionar otra fecha.</span></div>';
				var section = '';
				section += 	'<div class="row no-margin">';
				section += 		'<div class="cover__title__container">';
				section += 			'<hr class="cover__title__hr">';
				section += 			'<h3 class="cover__title__text font-size-30">Cineteca Nacional de Chile</h3>';
				section += 		'</div>';
				section += 	'</div>';
				section += 	'<div class="container">';
				section += 		'<div class="row" data-equalize="target" data-mq="tablet-down" data-eq-target=".box">';
				section += 			data;
				section += 		'</div>';
				section += 	'</div>';
				$('#agenda-cineteca-container').html(section);
			});
			$.post($('#site-url').val()+"/wp-json/search/actividades", { date: selected }, function(data){
				if(data['status'] == -1){ window.location.href = '/bad-request/'; }
				data = data != '' ? data : '<div class="gr-12 gutter-double gutter@tablet font-centered"><span style="color: #606060;font-size: 24px; display: block; margin-bottom: 2rem;">No contamos con actividades para este día.</span><span style="color: #606060;font-size: 24px;">Vuelve al calendario para seleccionar otra fecha.</span></div>' ;
				var section = '';
				section += 	'<div class="row no-margin">';
				section += 		'<div class="cover__title__container">';
				section += 			'<hr class="cover__title__hr">';
				section += 			'<h3 class="cover__title__text font-size-30">Otras actividades</h3>';
				section += 		'</div>';
				section += 	'</div>';
				section += 	'<div class="container">';
				section += 		'<div class="row" data-equalize="target" data-mq="tablet-down" data-eq-target=".box">';
				section += 			data;
				section += 		'</div>';
				section += 	'</div>';
				$('#agenda-actividades-container').html(section);
			});
		});
	});

	jQuery.each(jQuery('.calendar-box-cineteca'), function () {

		$('#datepicker-cineteca').datepicker($.datepicker.regional['es'] = {
			closeText: 'Cerrar',
			prevText: '<Ant',
			nextText: 'Sig>',
			currentText: 'Hoy',
			monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
			monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
			dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
			dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
			dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
			weekHeader: 'Sm',
			dateFormat: 'dd/mm/yy',
			firstDay: 1,
			isRTL: false,
			showMonthAfterYear: false,
			yearSuffix: ''
		});

		$("#datepicker-cineteca").on("change",function(){
			var selected = $(this).val().split('/').reverse().join('');
			$.post($('#site-url-cineteca').val()+"/wp-json/format/date", { date: selected }, function(data){
				if(data['status'] == -1){ window.location.href = '/bad-request/'; }
				$('#actual-date-cineteca').html(data);
			});
			$.post($('#site-url-cineteca').val()+"/wp-json/search/cineteca_agenda", { date: selected }, function(data){
				if(data['status'] == -1){ window.location.href = '/bad-request/'; }
				data = data != '' ? data : '<div class="gr-12 gutter-double gutter@tablet font-centered"><span style="color: #606060;font-size: 24px; display: block; margin-bottom: 2rem;">No contamos con funciones para este día.</span><span style="color: #606060;font-size: 24px;">Vuelve al calendario para seleccionar otra fecha.</span></div>';
				var section = '';
				section += 	'<div class="container">';
				section += 		'<div class="row" data-equalize="target" data-mq="tablet-down" data-eq-target=".box">';
				section += 			data;
				section += 		'</div>';
				section += 	'</div>';
				$('#agenda-container-cineteca').html(section);
			});
		});
	});

	jQuery.each(jQuery('.calendar-box--single'), function () {

			var url = window.location.href;
			url = url.substring(0, url.indexOf('/ticketera'));

			$.post(url+"/wp-json/check/date", {}, function(data){

				var availableDates = [];
				data.forEach(value => {
					availableDates[ new Date( value ) ] = new Date( value );
				});

				$('#datepickerSingle').datepicker($.datepicker.regional['es'] = {
					closeText: 'Cerrar',
					prevText: '<Ant',
					nextText: 'Sig>',
					currentText: 'Hoy',
					monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
					monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
					dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
					dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
					dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
					weekHeader: 'Sm',
					dateFormat: 'dd/mm/yy',
					firstDay: 1,
					isRTL: false,
					showMonthAfterYear: false,
					yearSuffix: '',
					beforeShowDay: function(date) {
						var highlight = availableDates[date];
						if( highlight ) {
							return [true, 'ui-datepicker-event-day calendar-box--single__date', 'Fecha con evento asociado'];
						} else {
							return [false, '', ''];
						}
					}
				});
			});

		$("#datepickerSingle").on("change",function(){
			$('[data-next="ticketSecondStep"]').removeClass('disabled');

			var event_id = $('#event-id').val();
			var selected = $(this).val().split('/').reverse().join('-');
			$.post(url+"/wp-json/check/hours", { date: selected, event_id: event_id }, function(data){
				if(data['status'] == -1){ window.location.href = '/bad-request/'; }

				var tabla = '';
				if(data['horarios'] != '') {
					tabla = 	'<table class="timePickerTable">';
					tabla += 		'<thead>';
					tabla +=			'<tr>';
					tabla +=				'<th class="timePickerHeader">Horarios disponibles</th>';
					tabla +=			'</tr>';
					tabla +=		'</thead>';
					tabla +=		'<tbody>';
					data['horarios'].forEach( horario => {
						tabla +=		'<tr>';
						tabla +=			'<td>';
						tabla +=				'<div class="timePickerForm">';
						tabla +=					'<input type="radio" name="timePicker" id="timePicker-' + horario['horario'] + '" value="' + horario['horario'] + '">';
						tabla +=					'<label for="timePicker-' + horario['horario'] + '">' + horario['horario'] + ' horas</label>';
						tabla +=				'</div>';
						tabla +=			'</td>';
						tabla +=		'</tr>';
					});
					tabla +=		'</tbody>';
					tabla +=	'</table>';
				}
				$('#timePickerContainer').html(tabla);
			});
		});
	});

	//Detectar cambio de valores en Radio Button
	$(document).on('change', 'input[name="timePicker"]', function(){
		if($(this).val() != '' && $(this).val() != undefined) {
			$('[data-next="ticketSecondStep"]').removeClass('disabled');
		}
	});

	//Funcion de pasar a 2da etapa de ticketera
	jQuery.each(jQuery('[data-next="ticketSecondStep"]'), function (index, element) {
		$(element).on('click', function() {
			var fecha = $('#datepickerSingle').val(), hora = $('input[name="timePicker"]:checked').val();
			if( fecha != '' && ( hora != '' && hora != undefined ) ) {
				$('#ticketFirstStep').hide();
				$('#ticketSecondStep').show();
				jQuery.each(jQuery('[data-stage="first"]'), function (index, element) {
					$(element).addClass('done');
				});
				$('.ticket--stage[data-stage="second"]').addClass('active');
			}
		});
	});

	$(document).on('submit', '#formFilterCineteca', function(e) {
		e.preventDefault();

		var $inputs = $('#formFilterCineteca :input');
		var values = {};
		$inputs.each(function() {
			values[this.name] = $(this).val();
		});

		if( values['buscar-termino'] != '' || (values['buscar-periodo'] != null && values['buscar-periodo'] != '') || (values['buscar-categoria'] != null && values['buscar-categoria'] != '') || (values['buscar-formato'] != null && values['buscar-formato'] != '') ) {

			jQuery.each(jQuery('[data-block-on-load]'), function (index, element) {
				$(element).addClass('loading');
			});

			var form = $(this);
			var url = form.attr('action');
			var data = form.serialize();

			$.ajax({
				url: url,
				method: "POST",
				data: data,
				success: function (response) {
					if(response['posts'] == '') {
						response['posts'] = '<div class="gr-12 font-centered"><span class="search-results-text">No se encontraron resultados. Intente con parámetros de búsqueda distintos.</span></div>';
					} else {
						response['posts'] = '<div class="gr-12 font-centered gutter-bottom-2"><span class="search-results-text">Resultados de búsqueda</span></div>' + response['posts'];
					}

					$('#verCatalogoCompleto').addClass('hide');
					$('#searchClearCineteca').removeClass('hide');
					$('#peliculasDestacadasCineteca').addClass('hide');

					$('#searchResultsCineteca').html(response['posts']);
					jQuery.each(jQuery('[data-block-on-load]'), function (index, element) {
						$(element).removeClass('loading');
					});

					var highestDiv = 0;
					setTimeout(function(){
						jQuery.each(jQuery('#searchResultsCineteca .box.box--post'), function () {
							if($(this)[0].offsetHeight > highestDiv) {
								highestDiv = $(this)[0].offsetHeight;
							}
						});
						jQuery.each(jQuery('#searchResultsCineteca .box.box--post'), function (index, element) {
							$(element).height(highestDiv);
						});
					}, 1000);

					$('#searchResultsCineteca').removeClass('hide');

					$('html, body').animate({ scrollTop: $('#searchResultsCineteca').offset().top - 200 }, 'slow', function () {});

					var actualIndex = parseInt(response['index']);
					var paginationContent = '';

					if( parseInt(response['found_posts']) > 12 ) {
						if(actualIndex > 1) {
							paginationContent += '<a class="prev page-numbers" href="#searchResultsCineteca" data-pagination-cineteca data-pagination-cineteca-index="' + (actualIndex-1) + '"></a>';
						}

						var pagesAmount = Math.ceil(parseInt(response['found_posts']) / 12);
						for (var i = 0; i < pagesAmount; i++) {
							var current = '';
							if( (i+1) == actualIndex ) {
								paginationContent += '<span class="' + current + '">' + (i+1) + '</span>';
							} else {
								paginationContent += '<a class="' + current + '" href="#searchResultsCineteca" data-pagination-cineteca data-pagination-cineteca-index="' + (i+1) + '">' + (i+1) + '</a>';
							}
						};

						if(actualIndex < pagesAmount) {
							paginationContent += '<a class="next page-numbers" href="#searchResultsCineteca" data-pagination-cineteca data-pagination-cineteca-index="' + (actualIndex+1) + '"></a>';
						}

						$('#paginationBoxCineteca').html(paginationContent);
						$('#paginationResultsCineteca').removeClass('hide');
					} else {
						$('#paginationResultsCineteca').addClass('hide');
					}
				},
				error: function (response) {
					console.log('error', response);
				}
			});
		}
	});

	$('body').on('click', '[data-pagination-cineteca]', function(element) {
		$('#page_index').val(parseInt(element.currentTarget.getAttribute('data-pagination-cineteca-index')));
		$('#searchGoCineteca').click();

		$('html, body').animate({ scrollTop: $('#searchResultsCineteca').offset().top - 200 }, 'slow', function () {});
	});

	$('#searchClearCineteca').on('click', function(){
		$('#searchResultsCineteca').addClass('hide');
		$('#searchClearCineteca').addClass('hide');
		$('#paginationResultsCineteca').addClass('hide');

		$('#peliculasDestacadasCineteca').removeClass('hide');
		$('#verCatalogoCompleto').removeClass('hide');

		$('#searchResultsCineteca').html('');

		$('#selectBuscarTermino').val('');
		$('#selectBuscarPeriodo').val('');
		$('#selectBuscarCategoria').val('');
		$('#selectBuscarFormato').val('');
	});

	$(document).on('submit', '#formNewsletterSuscription', function(e) {
		e.preventDefault();

		var $inputs = $('#formNewsletterSuscription :input');
		var values = {};
		$inputs.each(function() {
			values[this.name] = $(this).val();
		});

		$('#formNewsletterSuscription').validate();
		if( $('#formNewsletterSuscription').valid() ) {

			jQuery.each(jQuery('[data-block-on-load]'), function (index, element) {
				$(element).addClass('loading');
			});

			var form = $(this);
			var url = form.attr('action');
			var data = form.serialize();

			$.ajax({
				url: url,
				method: "POST",
				data: data,
				success: function (response) {
					$('#form-newsletter--container').html('<div class="row gutter-vertical-2"><div class="gr-12 gutter gutter-double@tablet font-centered"><span class="search-results-text">'+ response +'</span></div></div>');
				},
				error: function (response) {
					console.log('error', response);
					$('#form-newsletter--container').html('<div class="row gutter-vertical-2"><div class="gr-12 gutter gutter-double@tablet font-centered"><span class="search-results-text">'+ response +'</span></div></div>');
				}
			});
		}
	});

	jQuery.each(jQuery('[data-trigger-agenda]'), function (index, element) {
		$(element).on('click', function(){
			var ajaxurl = $('#cclm_admin_ajax_url').val();
			$.ajax({
				url: ajaxurl,
				method: 'POST',
				data: {
					action: 'load_cclm_agenda'
				},
				success: function (response) {
					$('#agenda-dynamic-container').html(response['data']);
				},
				error: function (response) {
					console.log('error', response);
				}
			});

		});
	});

	jQuery.each(jQuery('[data-next-section]'), function (e) {
	  var $this = $(this);
	  $this.on('click', function() {
	    var nextSection = $this.closest('section').next('section');
	    $('html, body').animate({ scrollTop: nextSection.offset().top }, 1000);
	  });
	});

	jQuery.each(jQuery('[data-skip-calendar]'), function (e) {
	  var $this = $(this);
	  $this.on('click', function() {
	    $('html, body').animate({ scrollTop: $('#calendarAnchor').offset().top - 64 }, 500);
	  });
	});
});
