(function (window, document, $) {
	"use strict";
	var DEBUG = true;
	var $window = $(window),
		$document = $(document),
		$body = $('body'),
		$mainNav, $mainHeader,
		$nav = $('.nav-bar');

	var LARGE_DOWN = 'screen and (max-width: 1024px)',
		BOOK_DOWN = 'screen and (max-width: 900px)',
		MEDIUM_DOWN = 'screen and (max-width: 850px)',
		TABLET_DOWN = 'screen and (max-width: 768px)',
		SMALL_DOWN = 'screen and (max-width: 600px)',
		PHABLET_DOWN = 'screen and (max-width: 500px)',
		ZERO = 'screen and (max-width: 0px)',
		LARGE_SIZE = 1024,
		MEDIUM_SIZE = 850,
		TABLET_SIZE = 768,
		SMALL_SIZE = 600,
		PHABLET_SIZE = 500;

	var throttle = function (fn) {
		return setTimeout(fn, 1);
	};

	var mqMap = function (mq) {
		var MQ = '';

		switch (mq) {
			case 'large-down':
				MQ = LARGE_DOWN;
				break;
			case 'book-down':
				MQ = BOOK_DOWN;
				break;
			case 'medium-down':
				MQ = MEDIUM_DOWN;
				break;
			case 'tablet-down':
				MQ = TABLET_DOWN;
				break;
			case 'small-down':
				MQ = SMALL_DOWN;
				break;
			case 'phablet-down':
				MQ = PHABLET_DOWN;
				break;
		}

		return MQ;
	};

	var mqMapString = function (mq) {
		var MQ = '';

		switch (mq) {
			case 'large-down':
				MQ = LARGE_SIZE;
				break;
			case 'medium-down':
				MQ = MEDIUM_SIZE;
				break;
			case 'tablet-down':
				MQ = TABLET_SIZE;
				break;
			case 'small-down':
				MQ = SMALL_SIZE;
				break;
			case 'phablet-down':
				MQ = PHABLET_SIZE;
				break;
		}

		return MQ;
	};

	var normalize = (function () {
		var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
			to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
			mapping = {};

		for (var i = 0, j = from.length; i < j; i++)
			mapping[from.charAt(i)] = to.charAt(i);

		return function (str) {
			var ret = [];
			for (var i = 0, j = str.length; i < j; i++) {
				var c = str.charAt(i);
				if (mapping.hasOwnProperty(str.charAt(i)))
					ret.push(mapping[c]);
				else
					ret.push(c);
			}
			return ret.join('');
		};
	})();

	//TODO: watch
	// String.prototype.capitalize = function() {
	// 	return this.charAt(0).toUpperCase() + this.substring(1).toLowerCase();
	// }

	//APP
	var App = function () {
		this.path = $('body').attr("data-path");
		this.home = $('body').attr("data-home");
		this.ajaxURL = '/wp-admin/admin-ajax.php';
		this.loadLegacyAssets();

		var app = this;
	};

	App.prototype = {
		//TODO:
		//Starting settings
		onReady: function () {
			this.setGlobals();
			this.autoHandleEvents($('[data-func]'));
			this.handleMobileTables();
			this.conditionalInits();
			this.deploySelect();
			this.deployValue();
			this.deviceCity();
			this.stickyParent();
			this.initTextCounter();

			this.datacalendar();

			this.initModalFilters();

			this.initSubmitOnChange();
			this.initSelector();
			this.leafMap();
			this.doZoom();
		},
		onLoad: function () {
			var app = this;

			$('[data-equalize="children"][data-mq="large-down"]').equalizeChildrenHeights(true, LARGE_DOWN);
			$('[data-equalize="children"][data-mq="book-down"]').equalizeChildrenHeights(true, BOOK_DOWN);
			$('[data-equalize="children"][data-mq="medium-down"]').equalizeChildrenHeights(true, MEDIUM_DOWN);
			$('[data-equalize="children"][data-mq="tablet-down"]').equalizeChildrenHeights(true, TABLET_DOWN);
			$('[data-equalize="children"][data-mq="small-down"]').equalizeChildrenHeights(true, SMALL_DOWN);
			$('[data-equalize="children"][data-mq="phablet-down"]').equalizeChildrenHeights(true, PHABLET_DOWN);
			$('[data-equalize="children"][data-mq="false"]').equalizeChildrenHeights(true, ZERO);
			$('[data-equalize="target"][data-mq="large-down"]').equalizeTarget(true, LARGE_DOWN);
			$('[data-equalize="target"][data-mq="book-down"]').equalizeTarget(true, BOOK_DOWN);
			$('[data-equalize="target"][data-mq="medium-down"]').equalizeTarget(true, MEDIUM_DOWN);
			$('[data-equalize="target"][data-mq="tablet-down"]').equalizeTarget(true, TABLET_DOWN);
			$('[data-equalize="target"][data-mq="small-down"]').equalizeTarget(true, SMALL_DOWN);
			$('[data-equalize="target"][data-mq="phablet-down"]').equalizeTarget(true, PHABLET_DOWN);
			$('[data-equalize="target"][data-mq="false"]').equalizeTarget(true, ZERO);

			if ($('[data-role="scroll-navigation"]').length) {
				this.scrollNavigation($('[data-role="scroll-navigation"]'));
			}

			if ($('[data-role="scroll-navigation-list"]').length) {
				this.scrollNavigationList($('[data-role="scroll-navigation-list"]'));
			}

			if ($('[data-role="horizon-navigation"]').length) {
				this.horizonNavigation($('[data-role="horizon-navigation"]'));
			}

			if (document.querySelector('[data-masonry-grid]')) {
				throttle(function () {
					app.masonry = new Masonry(document.querySelector('[data-masonry-grid]'), {
						itemSelector: '.masonry-item',
						percentPosition: true
					});
				});
			}

			$window.trigger('resize');
		},

		onResize: function () {
			var app = this;
			throttle(function () {
				// app.setFixedHeader();
			});
		},

		onScroll: function () {

			console.log('scrolling');
		},

		loadLegacyAssets: function () {
			// voy a asumir que cualquier browser que no soporte <canvas> es un oldIE (IE8-)
			if (Modernizr.canvas) {
				return false;
			}
			Modernizr.load({
				load: this.path + 'dist/js/libs/selectivizr.min.js'
			});
		},

		autoHandleEvents: function ($elements) {
			if (!$elements || !$elements.length) {
				return false;
			}
			var self = this;
			$elements.each(function (i, el) {
				var func = el.getAttribute('data-func') || false,
					evts = el.getAttribute('data-events') || 'click.customStuff';
				if (func && typeof (self[func]) === 'function') {
					$(el)
						.off(evts)
						.on(evts, $.proxy(self[func], self));
				}
			});
		},

		setEnquire: function () {
			var app = this,
				$mutable = $('[data-mutable]');

			enquire.register(LARGE_DOWN, [{
				match: function () {
					app.moveElements($mutable.filter('[data-mutable="large-down"]'), 'mobile');
				},
				unmatch: function () {
					app.moveElements($mutable.filter('[data-mutable="large-down"]'), 'desktop');
				}
			}]);

			enquire.register(BOOK_DOWN, [{
				match: function () {
					app.moveElements($mutable.filter('[data-mutable="book-down"]'), 'mobile');
				},
				unmatch: function () {
					app.moveElements($mutable.filter('[data-mutable="book-down"]'), 'desktop');
				}
			}]);

			enquire.register(TABLET_DOWN, [{
				match: function () {
					app.moveElements($mutable.filter('[data-mutable="tablet-down"]'), 'mobile');
				},
				unmatch: function () {
					app.moveElements($mutable.filter('[data-mutable="tablet-down"]'), 'desktop');
				}
			}]);

			enquire.register(MEDIUM_DOWN, [{
				match: function () {
					app.moveElements($mutable.filter('[data-mutable="medium-down"]'), 'mobile');
				},
				unmatch: function () {
					app.moveElements($mutable.filter('[data-mutable="medium-down"]'), 'desktop');
				}
			}]);

			enquire.register(SMALL_DOWN, [{
				match: function () {
					app.moveElements($mutable.filter('[data-mutable="small-down"]'), 'mobile');
				},
				unmatch: function () {
					app.moveElements($mutable.filter('[data-mutable="small-down"]'), 'desktop');
				}
			}]);

			enquire.register(PHABLET_DOWN, [{
				match: function () {
					app.moveElements($mutable.filter('[data-mutable="phablet-down"]'), 'mobile');
				},
				unmatch: function () {
					app.moveElements($mutable.filter('[data-mutable="phablet-down"]'), 'desktop');
					$('[data-role="nav-body"]').removeClass('deployed').removeAttr('style');
				}
			}]);

			// para qeu todo funcione bien en movil
			// se gatilla el evento resize en window
			$window.trigger('resize');
		},

		conditionalInits: function ($context) {
			if (!$context) {
				$context = $document;
			}
			// delegaciones directas
			if ($context.find('[data-func]').length) {
				this.autoHandleEvents($context.find('[data-func]'));
			}

			if ($('[data-role="stickyheader"]').length) {
				this.stickyheader($('[data-role="stickyheader"]'));
			}

			// si no reconoces matchmedia no mereces enquire
			if (window.matchMedia) {
				this.setEnquire();
			}

		},

		setGlobals: function () {
			$body = $('body');
			$mainHeader = $('#main-header');
			$mainNav = $('#main-nav');
			if ($mainNav.length) {
				this.navPos = $mainNav.offset().top;
			}
		},

		debug: function (message) {
			DEBUG && console.log(message);
		},

		moveElements: function ($set, type) {
			var areaType = 'data-' + type + '-area',
				groups = $set.groupByAtt(areaType);

			groups.forEach(function ($group) {
				var $target = $('[data-area-name="' + $group.first().attr(areaType) + '"]');

				$group.sort(function (a, b) {
					return $(a).data('order') - $(b).data('order');
				});

				$group.appendTo($target);
			});
		},

		//FUNC:

		mapSectionsPositions: function ($items) {
			var map = [];
			$items.each(function (index, el) {
				var $el = $(el),
					$target = $($el.attr('href')),
					targetOffset = $target.offset();

				targetOffset.bottom = targetOffset.top + $target.height();

				map.push({
					$item: $el,
					offset: targetOffset,
					selector: $el.attr('href')
				});
			});

			return map;
		},

		listSectionsPositions: function ($items) {
			var map = [];
			$items.each(function (index, el) {
				var $el = $(el),
					$target = $($el.children().attr('href')),
					targetOffset = $target.offset();

				if (typeof ($target.val()) !== 'undefined') {

					targetOffset.bottom = targetOffset.top + $target.height();

					map.push({
						$item: $el,
						offset: targetOffset,
						selector: $el.children().attr('href')
					});
				}
			});

			return map;
		},

		setFixedHeader: function () {
			if (Modernizr.mq(VERTICAL_TABLETS_DOWN)) {
				var headerHeight = document.querySelector('#main-header').offsetHeight;
				document.body.style.marginTop = headerHeight + 14 + 'px';
			} else {
				document.body.style.marginTop = 0;
			}
		},

		blockScroll: function (event) {
			event.stopPropagation();
		},

		//GENERAL

		handleMobileTables: function () {
			$('.regular-content-area table').each(function (i, table) {
				$(table).wrap('<div class="regular-content-table-holder"></div>');
			});
		},

		scrollNavigation: function ($nav) {
			var app = this,
				$navItems = $nav.children(),
				locationsMap = this.listSectionsPositions($navItems);

			$window.on('resize.ScrollNav', function () {
				locationsMap = app.listSectionsPositions($navItems);
			});

			$window.on('scroll.ScrollNav', function () {
				var scrollPosition = $window.scrollTop();

				locationsMap.forEach(function (item_info) {
					var $horizon = item_info.$item.find('a').attr('href'),
						scrolltop = item_info.offset.top;

					if (scrollPosition > (scrolltop - 200)) {
						$navItems.removeClass('current');
						item_info.$item.addClass('current');
					} else if (scrollPosition < (scrolltop - 200)) {
						item_info.$item.removeClass('current');
					}
				});
			});
		},

		scrollNavigationList: function ($nav) {
			var app = this,
				$navItems = $nav.children(),
				locationsMap = this.listSectionsPositions($navItems);


			$window.on('resize.ScrollNav', function () {
				locationsMap = app.listSectionsPositions($navItems);
			});

			var scrollPosition = $window.scrollTop();

			if (scrollPosition < locationsMap[0].offset.top) {
				$navItems.removeClass('current');
			}

			locationsMap.forEach(function (item_info) {
				var offset = item_info.$item.children().data('offset') || 100;

				if (scrollPosition > (item_info.offset.top - (offset + 1))) {
					$navItems.removeClass('current');
					item_info.$item.addClass('current');
				}
			});

			$window.on('scroll.ScrollNav', function () {
				var scrollPosition = $window.scrollTop();

				if (scrollPosition < locationsMap[0].offset.top) {
					$navItems.removeClass('current');
				}

				locationsMap.forEach(function (item_info) {
					var offset = item_info.$item.children().data('offset') || 100;

					if (scrollPosition > (item_info.offset.top - (offset + 1))) {
						$navItems.removeClass('current');
						item_info.$item.addClass('current');
					}
				});
			});
		},

		horizonsPositions: function ($items) {
			var map = [];
			$items.each(function (index, el) {
				var $el = $(el),
					$target = $('#' + $el.attr('id')),
					targetOffset = $el.offset();

				targetOffset.bottom = targetOffset.top + $target.height();

				map.push({
					$item: $el,
					offset: targetOffset,
					selector: $el.attr('id')
				});
			});

			return map;
		},

		horizonNavigation: function ($main) {
			var app = this,
				$horizons = $($main.find('[data-horizon]')),
				pollPosition = this.horizonsPositions($horizons);

			$window.on('scroll', function () {
				var scrollPosition = $window.scrollTop();


				pollPosition.forEach(function (item_info) {
					var $this = $('#' + item_info.selector),
						$offset = $this.data('offset') || 700;

					if (scrollPosition > (item_info.offset.top - $offset)) {
						var display = $window.width();
						$horizons.removeClass('current');
						item_info.$item.addClass('current');
						item_info.$item.addClass('visited').addClass('swaped').removeClass('wait');

						if (!$this.prev().hasClass('listening')) {
							$this.prev().addClass('visited');
						}

						if ($this.is(':last-child') || display <= TABLET_SIZE) {
							item_info.$item.addClass('visited').addClass('swaped').removeClass('wait');
						}

						if (!item_info.$item.hasClass('visited')) {
							item_info.$item.removeClass('wait');
						}
					}
				});
			});
		},

		getShareCount: function ($elements) {
			// se setea el api de google plus primero
			// api key publico
			// if( typeof gapi !== 'undefined' ){
			//     gapi.client.setApiKey('AIzaSyCKSbrvQasunBoV16zDH9R33D88CeLr9gQ');
			// }

			$elements.each(function (index, element) {
				var type = element.getAttribute('data-type'),
					url = element.getAttribute('data-url'),
					jsonUrl = '',
					data = {};

				var params = {
					nolog: true,
					id: url,
					source: "widget",
					userId: "@viewer",
					groupId: "@self"
				};

				if (type === 'facebook') {
					jsonUrl = 'http://graph.facebook.com/';
					data.id = url;
				} else if (type === 'twitter') {
					// Url obsoleta.
					//jsonUrl = 'http://urls.api.twitter.com/1/urls/count.json';
					//data.url = url;
					return;
				} else if (type === 'linkedin') {
					jsonUrl = 'http://www.linkedin.com/countserv/count/share';
					data.format = 'jsonp';
					data.url = url;
				} else {
					// gapi.client.rpcRequest('pos.plusones.get', 'v1', params).execute(function(resp) {
					//     console.log('count:', resp.result.metadata.globalCounts.count);
					// });
				}

				$.ajax({
					method: 'GET',
					url: jsonUrl,
					data: data,
					dataType: 'jsonp'
				}).then(function (response) {
					var count = '';

					// se saca el valor de cada red segun lo que responda el API correspondiente
					if (type === 'facebook') {
						count = response.shares;
					} else if (type === 'twitter') {
						count = response.count;
					} else if (type === 'linkedin') {
						count = response.count;
					} else {
						// google
					}


					// prevencion de error en caso de false o undefined
					count = count ? count : 0;
					element.textContent = count;
				});
			});
		},


		deployTarget: function (event) {
			event.preventDefault();

			var $item = $(event.currentTarget),
				target = $item.data('target'),
				$targetElem;

			if (!target) {
				console.warn('No se especificó un objetivo en el atributo "data-target":', target);
				return;
			}

			$targetElem = $(target);
			if (!$targetElem.length) {
				console.warn('El objetivo no fue encontrado o el atributo "data-target" no es un selector válido :', target);
				return;
			}

			$item.data('animating', true);

			if ($item.data('deployed')) {
				$targetElem
					.slideUp().promise()
					.then(function () {
						$item.data({
							deployed: false,
							animating: false
						}).removeClass('deployed');

						$targetElem.removeClass('deployed');
					});
			} else {
				$targetElem
					.slideDown().promise()
					.then(function () {
						$item.data({
							deployed: true,
							animating: false
						}).addClass('deployed');

						$targetElem.addClass('deployed');
					});
			}
		},

		deployMainNav: function (event) {
			event.preventDefault();

			var $btn = $(event.currentTarget),
				$nav = $('[data-role="nav-container"]'),
				$headerBody = $('[data-role="header-body"]');

			if ($btn.data('deployed')) {
				$btn
					.data('deployed', false)
					.removeClass('deployed');

				$nav.removeClass('deployed');
				$headerBody.removeClass('deployed');
			} else {
				$btn
					.data('deployed', true)
					.addClass('deployed');

				$nav.addClass('deployed');
				$headerBody.addClass('deployed');
			}
		},

		deployCollapsable: function (event) {
			event.preventDefault();

			var $item = $(event.currentTarget),
				$targetElem = $item.parents('.collapsable').find('.collapsable-body');

			$item.data('animating', true);

			if ($item.data('deployed')) {
				$targetElem
					.slideUp().promise()
					.then(function () {
						$item.data({
							deployed: false,
							animating: false
						}).removeClass('deployed');

						$targetElem.removeClass('deployed');
					});
			} else {
				$targetElem
					.slideDown().promise()
					.then(function () {
						$item.data({
							deployed: true,
							animating: false
						}).addClass('deployed');

						$targetElem.addClass('deployed');
					});
			}
		},

		toggleTarget: function (event) {
			event.preventDefault();

			$(event.currentTarget.getAttribute('data-target')).toggleClass('deployed');

			// expansion para cuando quiero enfocar algo despues de mostrarlo
			if (event.currentTarget.getAttribute('data-focus')) {
				$(event.currentTarget.getAttribute('data-focus')).focus();
			}
		},

		toggleActive: function (event) {
			event.preventDefault();

			var $current = $(event.currentTarget);
			var $inactive_text = $current.data('inactive-text') || '';
			var $active_text = $current.data('active-text') || '';

			if($inactive_text !== '' && $active_text !== ''){
				$current.text($current.text() == $inactive_text ? $active_text : $inactive_text);
			}

			$(event.currentTarget.getAttribute('data-target')).toggleClass('active');
			if( event.currentTarget.getAttribute('data-button') == 'agenda' ) {
				jQuery.each(jQuery('[data-button="agenda"]'), function (index, element) {
					$(element).toggleClass('active');
				});
				jQuery.each(jQuery('[data-inactive]'), function (index, element) {
					$(element).toggleClass('filter-grayscale--full');
				});
			} else {
				$current.toggleClass('active');
			}

			// expansion para cuando quiero enfocar algo despues de mostrarlo
			if (event.currentTarget.getAttribute('data-focus')) {
				$(event.currentTarget.getAttribute('data-focus')).focus();
			}
		},

		toggleTargetClass: function (event) {
			event.preventDefault();

			$(event.currentTarget.getAttribute('data-target-class')).toggleClass('deployed');

			// expansion para cuando quiero enfocar algo despues de mostrarlo
			if (event.currentTarget.getAttribute('data-focus')) {
				$(event.currentTarget.getAttribute('data-focus')).focus();
			}
		},

		tabControl: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget),
				$siblings = $($button.parents('[data-role="tab-parent"]').find('[data-func="tabControl"]')),
				$targets = $($button.parents('[data-role="tab-parent"]').find('[data-tab-name]')),
				$target = $($button.parents('[data-role="tab-parent"]').find('[data-tab-name="' + $button.data('target') + '"]'));

			$siblings.removeClass('active');
			$targets.removeClass('active');

			throttle(function () {
				$button.addClass('active');
				$target.addClass('active');
			});
		},

		parentTabControl: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget),
				$superparent = $($button.parents('[data-section="superparent"]')),
				$siblings = $($button.parents('[data-role="tab-parent"]').find('[data-func="parentTabControl"]')),
				$targets = $($superparent.find('[data-tab-name]')),
				$target = $($superparent.find('[data-tab-name="' + $button.data('target') + '"]')),
				$subtargets = $superparent.find('[data-child-name]'),
				$subtarget = $($superparent.find('[data-child-name="' + $button.data('target') + '"]')),
				$childtargets = $($superparent.find('[data-tab-name="'+$button.data('target')+'"] [data-func="childTabControl"]')),
				$childtarget = $($superparent.find('[data-tab-name="'+$button.data('target')+'"] [data-func="childTabControl"]:first-child'));

			$siblings.removeClass('active');
			$targets.removeClass('active');
			$childtargets.removeClass('active');
			$subtargets.removeClass('active');
			$($subtarget.find('[data-role="slider-slide"]')).removeClass('active');

			throttle(function () {
				$button.addClass('active');
				$target.addClass('active');
				$subtarget.addClass('active');
				$($subtarget.find('[data-role="slider-slide"][data-index="0"]')).addClass('active');
				$childtarget.addClass('active');
			});
		},

		childTabControl: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget),
				$group = $button.data('group'),
				$superparent = $($button.parents('[data-section="superparent"]')),
				$siblings = $($button.parents('[data-role="tab-parent"]').find('[data-func="childTabControl"]')),
				$targets = $('[data-child-name="' + $group + '"]').find('[data-role="slider-slide"]'),
				$target = $('[data-child-name="' + $group + '"]').find('[data-role="slider-slide"][data-target="'+$button.data('target')+'"]');

			$siblings.removeClass('active');
			$targets.removeClass('active');

			console.log($group);
			console.log( $('[data-child-name="' + $group + '"]') );

			throttle(function () {
				$button.addClass('active');
				$target.addClass('active');
			});
		},

		deployParent: function (event) {
			event.preventDefault();
			$(event.currentTarget).parents(event.currentTarget.getAttribute('data-parent')).toggleClass('deployed');
		},

		collapseControl: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget),
				$target = $('[data-target-name="' + $button.data('target') + '"]');

			throttle(function () {
				$button.toggleClass('active');
				$target.toggleClass('active');
			});
		},

		collapseTarget: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget),
				$target = $($button.parents()).find('[data-target-name="' + $button.data('target') + '"]'),
				$others = $('[data-func="collapseTarget"]');

			$others.removeClass('active');
			$($others.parents()).find('[data-target-name]').removeClass('active');

			throttle(function () {
				$button.toggleClass('active');
				$target.toggleClass('active');
			});
		},

		closeParent: function (event) {
			event.preventDefault();

			var $this = $(event.currentTarget);
			var $buttons = $('[data-target]');
			var $targets = $('[data-target-name]');
			var $nav = $('.nav-bar');

			$body.removeClass('filter-open')
			$nav.removeClass('less-zindex');
			$buttons.removeClass('active');
			$targets.removeClass('active');

			console.log($targets);
			console.log('watchout');
		},


		collapseSibling: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget);
			throttle(function () {
				$button.toggleClass('active').next().toggleClass('active').parent().toggleClass('active');

				var $therest = $($button.parent()).siblings();

				$therest.each(function (index, element) {
					var $thisone = $(element);
					$thisone.removeClass('active').children().removeClass('active');
				});
			});
		},

		showTab: function (event) {
			event.preventDefault();
			var $item = $(event.currentTarget);

			$('[data-tabname="' + $item.data('target') + '"]').addClass('active').siblings().removeClass('active');
			$item.addClass('active').siblings().removeClass('active');
		},

		showTabParent: function (event) {
			event.preventDefault();
			var $item = $(event.currentTarget);
			var $current = $($item.parent());
			var $deployer = $($current.parents('.teamside')).find('[data-role="open"]');


			$('[data-tabname="' + $item.data('target') + '"]').addClass('current').siblings().removeClass('current');
			$current.addClass('current').siblings().removeClass('current');
			$deployer.html($item.html());
		},

		deployMobileSearch: function (event) {
			event.preventDefault();

			var $button = $(event.currentTarget),
				$searchBox = $('#mobile-search-holder');

			$button.toggleClass('deployed');
			$searchBox.toggleClass('deployed');
		},

		printPage: function (event) {
			event.preventDefault();
			window.print();
		},

		goToTop: function (event) {
			event.preventDefault();
			$('html, body').animate({
				scrollTop: 0
			}, 800);
		},

		// printTarget : function(event){
		// 	event.preventDefault();
		//
		// 	var $clicker = $(event.currentTarget);
		// 	var $target = $($clicker.data('target'));
		// 	var target_html = $target.html();
		//
		// 	var printWindow = window.open('', '', 'height=400,width=800');
		// 	printWindow.document.write('<html><head><title>cotizacion</title>');
		// 	printWindow.document.write('<link rel="stylesheet" href="'+this.path+'/dist/css/main.css"><link href="https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800" rel="stylesheet">');
		// 	printWindow.document.write('</head><body >');
		// 	printWindow.document.write(target_html);
		// 	printWindow.document.write('</body></html>');
		// 	var printhis = function(who){
		// 		who.print();
		// 		who.document.close();
		// 	};
		//
		// 	setTimeout(printhis(printWindow), 5000);
		// },


		//HELPERS

		stickyheader: function ($element) {
			var top = $($element.data('target')).height() || $element.data('top') || 200;
			var offset = $element.data('offset') || 0;

			top = top - offset;

			var scroll = $window.scrollTop();
			if (scroll >= top) {
				$element.addClass('sticked');
			} else {
				$element.removeClass('sticked');
			}

			$window.scroll(function () {
				var scroll = $window.scrollTop();
				if (scroll >= top) {
					$element.addClass('sticked');
				} else {
					$element.removeClass('sticked');
				}
			});
		},

		autoImportant: function () {
			jQuery.each(jQuery('[data-eq-start]'), function () {
				var $element = $(this);
				var startpoint = $element.data('eq-start');
				var display = $window.width();

				$(window).on('resize', function () {
					if ($(this).width() != display) {
						display = $(this).width();
					}
					if (startpoint == 'tablet-down') {
						if (display > TABLET_SIZE) {
							$element.addClass('autoheight');
						} else {
							$element.removeClass('autoheight');
						}
					}
				});

				if (startpoint == 'tablet-down') {
					if (display > TABLET_SIZE) {
						$element.addClass('autoheight');
					} else {
						$element.removeClass('autoheight');
					}
				}
			});
		},

		initSelector: function () {
			$('.form-control select, form select').change(function (e) {
				var $input = $(this);

				e.preventDefault();

				$input.addClass('selected').removeClass('wait');
			});
		},

		tablecontrol: function () {
			jQuery.each(jQuery('table.tablepress'), function () {
				var $this = $(this),
					$btable_html = '<div class="single__table__scroll"><table class="' + $this.attr('class') + '">' + $this.html() + '</table></div>',
					$ftable_html = '<p class="single__table__message">Desliza para ver</p>',
					$block_html = $btable_html + $ftable_html;

				$this.replaceWith($block_html);
			});
		},

		deployValue: function () {
			jQuery.each(jQuery('[data-role="selector-show"]'), function () {

				$(this).on('change', function () {
					var $this = $(this),
						valor_select = $this.val(),
						$target = $('[name="'+ valor_select +'"]'),
						$other_targets = $this.parent().find('[data-role="selector-target"]');

					console.log(valor_select);

					$other_targets.addClass('hide').attr('required', false);
					$target.removeClass('hide').attr('required', true);
				});
			});
		},

		initModalFilters: function () {
			jQuery.each(jQuery('[data-role="semimodal-deployer"]'), function () {

				var $deployer = $(this),
				$modal_window = $('[data-role="'+ $deployer.data('target') +'"]'),
				$closer = $($modal_window.find('[data-role="semimodal-closer"]'));

				$deployer.on('click', function(e){
					e.preventDefault();

					$(this).addClass('active');
					$('body').addClass('modal-open');
					$modal_window.addClass('active');

					$closer.on('click', function(e){
						e.preventDefault();
						$deployer.removeClass('active');
						$modal_window.removeClass('active');
						$('body').removeClass('modal-open');
					});
				});
			});
		},

		initSubmitOnChange: function () {
			jQuery.each(jQuery('[data-control="submitOnChange"]'), function () {
				var $input = $(this);

				$input.change(function (e) {
					var $form = $input.parents('form'),
						display_width = $(window).width(),
						hideon_size = mqMapString($input.data('hideon')) || false;


					if(hideon_size !== false){
						if($(window).resize()){
							display_width = $(window).width();
						}

						if(display_width >= hideon_size){
							e.preventDefault();
							$form.submit();
						}
					}else{
						e.preventDefault();
						$form.submit();
					}

				});
			});
		},

		deploySelect: function () {
			jQuery.each(jQuery('[data-role="deploy-select"]'), function () {

				$(this).on('change', function () {
					var $this = $(this),
						valor_select = $this.val(),
						$target = $('[data-role="deploy-target-select"][data-target-name="' + $this.data('target') + '"]');
					if (valor_select == $this.data('target')) {
						$target.removeClass('hide').find('select').attr('required', true);
						console.log(valor_select);
					} else {
						$target.addClass('hide').find('select').attr('required', false);
					}
				});
			});
		},

		deviceCity: function () {
			var display = $('window').width();

			// console.log(displ);

			$('[data-check-width]').html(display);

			$(window).on('resize', function () {
				if ($(this).width() != display) {
					display = $(this).width();
					$('[data-check-width]').html(display);
				}
			});

			if (display > TABLET_SIZE) {
				$('[data-check-mobile]').html('DESKTOP (mayor a 768px)');
				// console.log('desktop');
			} else if (display >= PHABLET_SIZE && display <= TABLET_SIZE) {
				$('[data-check-mobile]').html('TABLET (menor a 768px - mayor a 480px)');
				// console.log('tablet');
			} else if (display <= PHABLET_SIZE) {
				$('[data-check-mobile]').html('MOBILE (menor a 480px)');
				// console.log('mobile');
			}
		},

		scrollToTarget: function (event) {
			event.preventDefault();

			var $item = $(event.currentTarget),
				target = $item.attr('href') || $item.data('target'),
				dataoffset = $item.data('offset') - 1 || 0,
				dataoffset_mobile = $item.data('offset-mobile') || dataoffset,
				datamove = 0;

			if (!$(target).length) {
				return;
			}

			if ($window.width() < 768) {
				datamove = $(target).offset().top - (dataoffset * .75)
			} else {
				datamove = $(target).offset().top - dataoffset
			}

			$('html, body').animate({
				scrollTop: datamove
			});
		},

		stickyParent: function () {
			jQuery.each(jQuery('[data-role="sticky-parent"]'), function () {
				var $this = $(this);
				var sticker_offset = parseInt($this.data('offset-parent')) || 130;
				var mobile_offset = parseInt($this.data('offset-mobile')) || 0;

				$this.stick_in_parent({ offset_top: 90 });
			});
		},

		leafMap: function () {
			jQuery.each(jQuery('[data-role="leaflet-map"]'), function () {
				var the_map = this;
				var lat = $(this).data('lat');
				var lng = $(this).data('lng');
				var popup_message = $(this).data('popup') || '';
				var map_zoom = $(this).data('zoom') || 14;

				//Marcadores
				var marcadores = L.layerGroup();

				//icono
				var the_icon = L.icon({
					iconUrl: app.path + 'dist/img/leafmap/marker-ida.png',
					shadowUrl: app.path + 'dist/img/leafmap/marker-shadow.png',
					iconSize:     [28, 40], // size of the icon
					iconAnchor:   [28, 40], // point of the icon which will correspond to marker's location
					popupAnchor:  [-16, -38] // point from which the popup should open relative to the iconAnchor
				});

				L.marker([lat, lng], {
					icon: the_icon,
				}).addTo(marcadores).bindPopup(popup_message);


				var	gray_layer_url = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png?',
						street_layer_url = 'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?',
						transport_layer_url = 'https://{s}.tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=e1957b259c1e4282a0e84048806a62cd&',
						commerce_layer_url = 'https://maps.heigit.org/openmapsurfer/tiles/roads/webmercator/{z}/{x}/{y}.png?',
						layer_after = 'access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw';

				var mbAttr = '<a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a>';


				var grayscale   = L.tileLayer(gray_layer_url + layer_after, {id: 'mapbox.light', attribution: mbAttr}),
					streets  = L.tileLayer(street_layer_url + layer_after, {id: 'mapbox.streets',   attribution: mbAttr}),
					transport  = L.tileLayer(transport_layer_url + layer_after, {id: 'mapbox.transport',   attribution: mbAttr}),
					commerce  = L.tileLayer(commerce_layer_url + layer_after, {id: 'mapbox.commerce',   attribution: mbAttr});

				var map = L.map(the_map, {
					center: [lat, lng],
					zoom: map_zoom,
					scrollWheelZoom: false,
					layers: [grayscale, marcadores]
				});

				//controles de tipo de mapa
				var baseLayers = { "Escala de gris": grayscale, "Transporte": transport, "Comercio": commerce,};
				L.control.layers(baseLayers).addTo(map);

				map.panTo(new L.LatLng(lat, lng));
			});
		},

		doZoom : function(){
			jQuery.each(jQuery('[data-role="zoom-img"]'), function () {
				var $image = $(this);
				var zoom_img = $image.data('zoom-img') || false;
				var zoom_type = $image.data('zoom-type') || 'hover';

				if(zoom_type == 'hover'){
					$image.mouseover( function(){
						$image.addClass('zoom-in');
					}).mouseleave( function(){
						$image.removeClass('zoom-in');
					});
					$image.zoom({ url: zoom_img });
				}else{
					$image.on(zoom_type, function(){
						$image.toggleClass('zoom-in');
					});
					$image.zoom({ url: zoom_img, on: zoom_type });
				}
			});
		},

		datacalendar: function () {
			jQuery.each(jQuery('[data-calendar-es]'), function () {
				var $calendar = $(this);
				var dateToday = new Date();
				$.datepicker.regional['es'] = {
					closeText: 'Cerrar',
					prevText: '<Ant',
					nextText: 'Sig>',
					currentText: 'Hoy',
					monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
					monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
					dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
					dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
					dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
					weekHeader: 'Sm',
					dateFormat: 'dd/mm/yy',
					firstDay: 1,
					isRTL: false,
					minDate: dateToday,
					showMonthAfterYear: false,
					yearSuffix: ''
				};

				$.datepicker.setDefaults($.datepicker.regional['es']);

				$calendar.datepicker({
					onSelect: function (d, i) {
						if (d !== i.lastVal) {
							$(this).change();
							$(this).attr('data-validizr-validity', 'true').addClass('valid-input').parent('.form-control').addClass('valid-control');
							$(this).removeClass('invalid-input').parent('.form-control').removeClass('invalid-control');
						}
					}
				});
			});

			jQuery.each(jQuery('[data-calendar-en]'), function () {
				var $calendar = $(this);
				var dateToday = new Date();
				$.datepicker.regional['en'] = {
					closeText: 'Close',
					prevText: '<Prev',
					nextText: 'Next>',
					currentText: 'Today',
					weekHeader: 'Sm',
					dateFormat: 'dd/mm/yy',
					firstDay: 1,
					isRTL: false,
					minDate: dateToday,
					showMonthAfterYear: false,
					yearSuffix: ''
				};

				$.datepicker.setDefaults($.datepicker.regional['en']);

				$calendar.datepicker({
					onSelect: function (d, i) {
						if (d !== i.lastVal) {
							$(this).change();
							$(this).attr('data-validizr-validity', 'true').addClass('valid-input').parent('.form-control').addClass('valid-control');
							$(this).removeClass('invalid-input').parent('.form-control').removeClass('invalid-control');
						}
					}
				});
			});
		},

		initTextCounter: function () {
			$('[data-textcounter]').keyup(function (e) {
				var $textarea = $(this),
					maxlength = parseInt($textarea.attr('maxlength')),
					valuelength = $textarea.val().length,
					countdown = $textarea.parent().find('[data-role="countdown"]');

				e.preventDefault();
				countdown.text(valuelength);

			});
		}
	};

	var app = new App();
	$document.ready(function () {
		app.onReady && app.onReady();
	});
	$window.on({
		'load': function () {
			app.onLoad && app.onLoad();
		}
	});

}(window, document, jQuery));

/////////////////////////////////////////
// Plugins y APIS
/////////////////////////////////////////
(function (window, $, undefined) {
	var $window = $(window);

	// pruebas personalizadas para modernizr
	Modernizr.addTest('device', function () {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	});


	var unique = function (arr) {
		var unique = [],
			i;

		for (i = 0; i < arr.length; i++) {
			var current = arr[i];
			if (unique.indexOf(current) < 0) {
				unique.push(current);
			}
		}
		return unique;
	};

	$.fn.svgfallback = function (callback) {
		if (Modernizr.svg) {
			return false;
		}

		return this.each(function () {
			this.src = this.getAttribute('data-svgfallback');
		});
	};

	$.fn.groupByAtt = function (attname) {
		var $set = this,
			groups = [],
			posibles = [];

		// se guardan todos los posibles valores
		$set.each(function (i, el) {
			posibles.push(el.getAttribute(attname));
		});

		// se quitan los elementos duplicados dejando solo los unicos
		posibles = unique(posibles);

		// se itera sobre las posibilidades y se agrupan los elementos
		posibles.forEach(function (value) {
			groups.push($set.filter('[' + attname + '="' + value + '"]'));
		});

		return groups;
	};

	$.fn.equalizeHeights = function (dinamic, mqException) {
		var items = this,
			eq_h = function ($collection) {
				var heightArray = [];

				$collection.removeClass('height-equalized').height('auto');

				if (!mqException || !Modernizr.mq(mqException)) {
					$collection.each(function (i, e) {
						heightArray.push($(e).outerHeight());
					});
					$collection.css({
						height: Math.max.apply(Math, heightArray)
					}).addClass('height-equalized').attr('data-max-height', Math.max.apply(Math, heightArray));
				}
			};

		setTimeout(function () {
			eq_h(items);
		}, 0);

		$('window').on('resize', function () {

			setTimeout(function () {
				eq_h(items);
			}, 10);
		});
	};

	$.fn.equalizeChildrenHeights = function (dinamic, mqException) {
		return this.each(function (i, e) {
			if ($(e).parents('[data-override-eq="true"]').length) {
				return;
			}
			$(e).children().equalizeHeights(dinamic, mqException);
		});
	};

	$.fn.equalizeTarget = function (dinamic, mqException) {
		return this.each(function (index, box) {
			$(box).find($(box).data('eq-target')).equalizeHeights(dinamic, mqException);
		});
	};

	$.fn.equalizeGroup = function (attname, dinamic, mqException) {
		var groups = this.groupByAtt(attname);

		groups.forEach(function ($set) {
			$set.equalizeHeights(dinamic, mqException);
		});

		return this;
	};

	$.fn.random = function () {
		var randomIndex = Math.floor(Math.random() * this.length);
		return $(this[randomIndex]);
	};
}(this, jQuery));